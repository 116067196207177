import * as React from 'react';

export const LeftArrowOutline = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <path
      d="M19.25 22.625 13.625 17l5.625-5.625"
      stroke="#0047ab"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={1}
      y={1}
      width={32}
      height={32}
      rx={16}
      stroke="#CACDD5"
      strokeWidth={2}
    />
  </svg>
);
