import {
  IconFacebook,
  IconLinkedin,
  IconTwitter,
  IconYoutube,
} from '@public/icons';
import { Button, Col, Input, Layout, Row, Space, Typography } from 'antd';
import React, { FC, useRef, useState } from 'react';
import Link from 'next/link';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { object, string } from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useModalComplete } from '@context/provider/modal';
import { useRouter } from 'next/router';
import { contactAPI } from '@apiClient/post-api';
import { activeModalComplete } from '@context/action/modal';

const { Footer } = Layout;
const { Paragraph } = Typography;

const FooterBlock: FC<{
  title: string;
  description?: string;
  menu?: any;
}> = ({ title, description, menu }) => {
  return (
    <div className="footer__block">
      <div className="block-title">{title}</div>
      {menu ? (
        menu.map(({ item, link, isExternalLink = false }, index) =>
          isExternalLink ? (
            <Link
              href={`${process.env.NEXT_PUBLIC_MAIN_SITE_URL}/${link}`}
              key={index}
            >
              <a className="block-item">{item}</a>
            </Link>
          ) : (
            <Link href={link} key={index}>
              <a className="block-item">{item}</a>
            </Link>
          ),
        )
      ) : (
        <Paragraph className="block-description">{description}</Paragraph>
      )}
    </div>
  );
};

const FooterComponent = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const formikRef = useRef(null) as any;
  const { dispatch } = useModalComplete();
  const [isLoading, setIsLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const socials = [
    {
      icon: IconFacebook,
      link: 'https://www.facebook.com/OfficialEkotek',
    },
    {
      icon: IconLinkedin,
      link: 'https://www.linkedin.com/company/official-ekotek/',
    },
    {
      icon: IconTwitter,
      link: 'https://x.com/official_ekotek',
    },
    {
      icon: IconYoutube,
      link: 'https://www.youtube.com/@official-ekotek',
    },
  ];

  const office = [
    {
      location: t('home.txtHanoi'),
      address: t('home.addressHanoi'),
    },
    {
      location: t('home.txtDanang'),
      address: t('home.addressDaNang'),
    },
  ];

  const menuItems = [
    {
      location: t('home.txtEkoiosTechnology'),
      menu: [
        {
          item: t('common.aboutUs'),
          link: 'about-us',
          isExternalLink: true,
        },
        {
          item: t('home.txtPortfolio'),
          link: 'portfolios',
          isExternalLink: true,
        },
        {
          item: t('home.txtProduct'),
          link: '',
          isExternalLink: true,
        },
        {
          item: t('home.txtNews'),
          link: 'news',
          isExternalLink: true,
        },
        {
          item: t('common.textCareers'),
          link: `${process.env.NEXT_PUBLIC_SITE_URL}`,
        },
        {
          item: t('common.buttonContactUs'),
          link: 'contact',
          isExternalLink: true,
        },
      ],
    },
    {
      location: t('common.services'),
      menu: [
        {
          item: t('home.txtBlockchain'),
          link: 'services/blockchain-development',
          isExternalLink: true,
        },
        {
          item: 'AI',
          link: 'services/ai-development',
          isExternalLink: true,
        },
        {
          item: t('home.txtWeb/AppDevelopment'),
          link: 'services/web-and-mobile-app-development',
          isExternalLink: true,
        },
        {
          item: t('service.serviceOndemand'),
          link: '',
          isExternalLink: true,
        },
      ],
    },
  ];

  const handleSubmit = async (data) => {
    const formData = new FormData();
    setIsLoading(true);
    const token = await executeRecaptcha();

    data = {
      ...data,
      url: `${process.env.NEXT_PUBLIC_SITE_URL}${router.asPath}`,
      _wpcf7_recaptcha_response: token,
    };

    for (const key in data) {
      formData.append(key, data[key]);
    }

    try {
      setIsLoading(true);
      await contactAPI
        .submitForm(11682, formData)
        .then(() => {
          setIsLoading(false);
          dispatch(activeModalComplete());
          formikRef.current.resetForm();
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const validationSubscribe = object({
    email: string()
      .email(t('common.txtPleaseEnter'))
      .required(t('common.txtFieldRequired')),
  });

  return (
    <>
      <Footer className="footer">
        <div className="container">
          <Row
            gutter={[
              {
                sm: 40,
                xxl: 100,
              },
              20,
            ]}
          >
            <Col md={8} xl={5}>
              <img
                src="/images/logo-light.svg"
                alt="Ekoios Logo"
                className="footer__logo"
              />
              <div className="footer__contact">
                <a
                  href="mailto:contact@ekotek.vn"
                  className="email information"
                >
                  <img src="/icons/icon-email.svg" alt="Email" />
                  <span>:</span>
                  <span className="information__description">
                    contact@ekotek.vn
                  </span>
                </a>
                <a href="tel:+84-24-6658-3530" className="phone information">
                  <img src="/icons/icon-phone.svg" alt="Phone" />
                  <span>:</span>
                  <span className="information__description">
                    +84-24-6658-3530
                  </span>
                </a>
              </div>
              <ul className="list socials">
                {socials.map(({ link, icon: Icon }, index) => (
                  <li className="item socials__item" key={index}>
                    <a href={link}>
                      <Icon />
                    </a>
                  </li>
                ))}
              </ul>
              {/* <ul className="list badge">
                <li className="item">
                  <img src="/images/logo-clutch.png" alt="Clutch" />
                </li>
                <li className="item">
                  <img src="/images/logo-goodfirms.png" alt="GoodFirms" />
                </li>
                <li className="item">
                  <img src="/images/logo-JBA.png" alt="JBA" />
                </li>
              </ul> */}
            </Col>
            <Col sm={12} md={12} xl={7}>
              {office.map(({ address, location }, index) => (
                <FooterBlock
                  title={location}
                  description={address}
                  key={index}
                />
              ))}
            </Col>
            <Col md={8} xl={5}>
              {menuItems.map(({ menu, location }, index) => (
                <FooterBlock title={location} menu={menu} key={index} />
              ))}
            </Col>
            <Col md={12} xl={7}>
              <FooterBlock
                title={t('home.txtEkoiosTechnology')}
                description={t('home.descFormSignUp')}
              />
              <Formik
                initialValues={{
                  email: '',
                }}
                innerRef={formikRef}
                onSubmit={handleSubmit}
                validationSchema={validationSubscribe}
              >
                <Form className="sign-up-form">
                  <Space size={20}>
                    <Field
                      name="email"
                      placeholder="Email address"
                      className="input-email"
                      component={Input}
                    />
                    <Button
                      htmlType="submit"
                      loading={isLoading}
                      className="button-submit"
                    >
                      {t('home.txtSignUp')}
                    </Button>
                  </Space>
                </Form>
              </Formik>
            </Col>
          </Row>
        </div>
      </Footer>
      <div className="copyright">{t('home.txtCopyRight')}</div>
    </>
  );
};

export default FooterComponent;
