import React from 'react';
import { Layout, BackTop } from 'antd';
import { withRouter, NextRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import { ConfigProvider } from 'antd';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import FooterComponent from '../Footer';
import { ModalProvider } from '@context/provider/modal';
import ModalApply from '@components/ModalApply';
import ModalComplete from '@components/ModalComplete';
import { useTranslation } from 'next-i18next';
import HeaderComponent from '@components/Header';
// import '../../styles/app.scss';

const { Content } = Layout;

interface Router extends NextRouter {
  path: string;
  breadcrumbName: string;
}

interface Props extends WithRouterProps {
  router: Router;
  className?: string;
  headerProps?: any;
}

const LayoutJob = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const { children, className, headerProps } = props;

  const menu = [
    {
      title: (
        <>
          <img src="/icons/home.svg" alt="icon home" />
          {t('home.txtCompanyWeb')}
        </>
      ),
      path: 'https://ekoios.com/?utm_source=ekoios_careers&utm_medium=careers&utm_campaign=redirect',
      isExternal: true,
    },
    {
      title: 'Jobs',
      path: '/jobs',
    },
    {
      title: 'Life at Ekotek',
      path: '/life-at-ekoios',
    },
    {
      title: 'Referral Program',
      path: '/referral',
    },
  ];

  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={process.env.NEXT_PUBLIC_CAPTCHA_KEY}
      scriptProps={{ async: true, defer: true, appendTo: 'body' }}
    >
      <ConfigProvider autoInsertSpaceInButton={false}>
        <ModalProvider>
          <Layout style={{ minHeight: '100vh' }} className={className}>
            <HeaderComponent menu={menu} {...headerProps} />
            <Content className="site-layout-background main-layout job-layout">
              {children}
            </Content>
            <FooterComponent />
            <BackTop />
            <ModalApply />
            <ModalComplete
              title={t('job.titleThankYou')}
              subtitle={t('job.subtitleThankYou')}
              buttonProps={{ text: t('job.buttonBackToJobs'), path: '/jobs' }}
              className="modal-complete-job"
            />
          </Layout>
        </ModalProvider>
      </ConfigProvider>
    </GoogleReCaptchaProvider>
  );
};

export default withRouter(LayoutJob);
